export default {
  sysCustom : '{msg}', // 系统自定义
  i1 : 'Welcome to my project',

  /* ------------------   其他   ------------------*/
  WeiXinFollow : 'Please pay attention to WeChat',

  /* ------------------   通用组件   ------------------*/
  submit : 'submit', // 提交按钮
  close : 'close', // 关闭按钮

  /* ------------------ 码主要内容展示 ------------------*/
  idisCode : 'CODE',

  // 导航栏
  navigatorIndex : 'home',
  navigatorSaleAfter : 'sales',
  navigatorCustomerService : 'service',

  // 产品信息
  productInfo : 'PRODUCT INFORMATION',
  productName : 'productName',
  batchNo : 'batchNo',
  productNo : 'productNo',
  barCode : 'barCode',
  categoryName : 'categoryName',
  unit : 'unit',
  price : 'price',
  size : 'size',
  big : 'big',
  trace : 'tracking details',

  // 产品条码
  barCodeTitle : 'BARCODE',

  // 产品介绍
  productDesc : 'PRODUCT INTRODUCTION',

  // 产品价格
  productPrice : 'PRICE',

  // 保质期
  ShelfLife : 'SHELFLIFE',
  days : 'days',
  remain : 'remain',
  expire : 'expired',

  // 特别推荐
  SpecialRecommendation : 'RECOMMENDATION',

  // 码上验证
  codeValidate : 'validate',
  codeValidatePlaceholder : 'Please enter the code to query',
  codeValidateTips : 'please enter the code to check',
  codeValidateTipsErr : 'check code error',
  loadMoreTips : 'Click to load more',
  successText : 'Thank you for your inquiry!',
  failureText :
      'If your verification code repeatedly inquires about non personal behavior, please confirm whether your product is purchased through official channels!',
  validTitle : 'The product is genuine!',
  invalidTitle : 'The product has been queried multiple times!',
  successTemplate : 'The {product} is genuine. Thank you for your inquiry',
  failureTemplate :
      'The product you inquired about is {product}, has been queried {count} times. If your verification code repeatedly inquires about non personal behavior, please confirm whether your product is purchased through official channels!',

  /* ------------------ 宫格-联系我们 ------------------*/
  // 联系我们
  contactUs : 'CONTACT US',
  contactUs_name : 'name',
  contactUs_name_placeholder : 'Please enter the name',
  contactUs_phone : 'phone',
  contactUs_phone_placeholder : 'Please enter the phone',
  contactUs_remark : 'remark',
  contactUs_remark_placeholder : 'Please enter the remark (0~150)',
  contactUs_submit : 'submit',

  /* ------------------ 宫格-报修管理 ------------------*/
  // 首页
  repair_title : 'repair',
  repair_status_true : 'guaranteed',
  repair_status_false : 'expired',
  repair_warranty_card : 'warranty',
  repair_warranty_to : 'warranty until',
  repair_remain_days : 'warranty days',
  repair_apply : 'apply',
  repair_history : 'history',
  repair_fault_check : 'self-check',
  repair_warranty_query_fail : 'Cannot find warranty information',

  // 提交报修页
  repair_create_title : 'create',
  repair_contact_info : 'BASIC',
  repair_creator : 'declarer',
  repair_creator_phone : 'phone',
  repair_creator_address : 'address',
  repair_detail : 'DESCRIPTION',
  repair_detail_des : 'description',
  repair_detail_pic : 'picture',
  repair_create_tips : 'tips:',
  repair_create_tips1 : '1.Please fill in truthfully',
  repair_create_tips2 : '2.Please keep the phone open',
  repair_placeholder_creator : 'Please enter the name',
  repair_placeholder_phone : 'Please enter the phone',
  repair_placeholder_address : 'Please enter the address',
  repair_placeholder_detail : 'Please enter the detail',

  // 报修历史
  repair_history_title : 'history',
  repair_status_wait : 'waiting',
  repair_status_deal : 'processed',
  repair_status_mail_wait : 'wait for mail',
  repair_status_mail_deal : 'mailed',
  repair_status_cancel : 'cancelled',
  repair_time : 'time',
  repair_reason : 'reason',
  repair_load_more : 'click to load more',
  repair_no_more_data : 'no more data',

  // 报修详情
  repair_detail_title : 'detail',
  repair_detail_status : 'status',
  repair_detail_no : 'no',
  repair_detail_time : 'time',
  repair_detail_detail : 'PROCESSING',
  repair_detail_detail_time : 'time',
  repair_detail_detail_desc : 'desc',
  repair_detail_detail_address : 'address',
  repair_detail_detail_express_company : 'company',
  repair_detail_detail_express_no : 'no',
  repair_detail_copy : 'copy',
  repair_detail_product : 'PRODUCT',
  repair_detail_product_batch : 'batchNo',
  repair_detail_desc : 'DESCRIPTION',
  repair_detail_desc_reason : 'reason',
  repair_detail_desc_picture : 'picture',
  repair_detail_contact : 'CONTACT',
  repair_detail_contact_name : 'name',
  repair_detail_contact_phone : 'phone',
  repair_detail_contact_address : 'address',
  repair_detail_cancel : 'cancel',

  /* ------------------ 宫格-维保历史记录 ------------------*/
  code_maintenance_history_code : 'code',
  code_maintenance_history_product : 'product',
  code_maintenance_history_scan_time : 'scan time',
  code_maintenance_history_scan_address : 'scan address',

  /* ------------------ 宫格-质保 ------------------*/
  warranty_title : 'WARRANTY',
  warranty_remark_title : 'REMARK',
  warranty_bill_title : 'INVOICE',
  warranty_create_title : 'ADD',

  // 质保详情
  warranty_product_warranty_card : 'WARRANTY CARD',
  warranty_product_name : 'product',
  warranty_idis_code : 'idisCode',
  warranty_batch_no : 'batchNo',
  warranty_buy_date : 'buyDate',
  warranty_over_date : 'overDate',
  warranty_price : 'price',
  warranty_store_name : 'store',
  warranty_customer_name : 'customer',
  warranty_customer_phone : 'customer',
  warranty_customer_address : 'address',
  warranty_buy_prove : 'invoice',
  warranty_remark_detail : 'remark',
  warranty_tips : 'Please long press to save',

  // 创建质保单
  warranty_code : 'Code',
  warranty_product : 'product',
  warranty_detail : 'userInfo',
  warranty_contact : 'warrantyInfo',
  warranty_create_name : 'userName',
  warranty_placeholder_creator : 'Please enter the name',
  warranty_create_phone : 'phone',
  warranty_placeholder_phone : 'Please enter the phone',
  warranty_create_address : 'address',
  warranty_placeholder_address : 'Please enter the address',
  warranty_create_date_buy : 'buyDate',
  warranty_create_date_to : 'toDate',
  warranty_remark : 'remark',
  warranty_create_price : 'price',
  warranty_placeholder_price : 'Please enter the price',
  warranty_create_choose_store : 'store',
  warranty_create_placeholder_choose_store : 'Please select store',
  warranty_pic : 'voucher',
  warranty_placeholder_remark : 'Please enter the remark',
  warranty_phone_alert : 'Phone format error!',
  warranty_price_alert : 'Price format error!',

  /* ------------------ elementUi(默认使用中文) ------------------*/
  el : {
    colorpicker : {
      confirm : '确定',
      clear : '清空'
    },
    datepicker : {
      now : '此刻',
      today : '今天',
      cancel : '取消',
      clear : '清空',
      confirm : '确定',
      selectDate : '选择日期',
      selectTime : '选择时间',
      startDate : '开始日期',
      startTime : '开始时间',
      endDate : '结束日期',
      endTime : '结束时间',
      prevYear : '前一年',
      nextYear : '后一年',
      prevMonth : '上个月',
      nextMonth : '下个月',
      year : '年',
      month1 : '1 月',
      month2 : '2 月',
      month3 : '3 月',
      month4 : '4 月',
      month5 : '5 月',
      month6 : '6 月',
      month7 : '7 月',
      month8 : '8 月',
      month9 : '9 月',
      month10 : '10 月',
      month11 : '11 月',
      month12 : '12 月',
      // week: '周次',
      weeks : {
        sun : '日',
        mon : '一',
        tue : '二',
        wed : '三',
        thu : '四',
        fri : '五',
        sat : '六'
      },
      months : {
        jan : '一月',
        feb : '二月',
        mar : '三月',
        apr : '四月',
        may : '五月',
        jun : '六月',
        jul : '七月',
        aug : '八月',
        sep : '九月',
        oct : '十月',
        nov : '十一月',
        dec : '十二月'
      }
    },
    select : {
      loading : '加载中',
      noMatch : '无匹配数据',
      noData : '无数据',
      placeholder : '请选择'
    },
    cascader : {
      noMatch : '无匹配数据',
      loading : '加载中',
      placeholder : '请选择',
      noData : '暂无数据'
    },
    pagination : {
      goto : '前往',
      pagesize : '条/页',
      total : '共 {total} 条',
      pageClassifier : '页'
    },
    messagebox : {
      title : '提示',
      confirm : '确定',
      cancel : '取消',
      error : '输入的数据不合法!'
    },
    upload : {
      deleteTip : '按 delete 键可删除',
      delete : '删除',
      preview : '查看图片',
      continue : '继续上传'
    },
    table : {
      emptyText : '暂无数据',
      confirmFilter : '筛选',
      resetFilter : '重置',
      clearFilter : '全部',
      sumText : '合计'
    },
    tree : {
      emptyText : '暂无数据'
    },
    transfer : {
      noMatch : '无匹配数据',
      noData : '无数据',
      titles : ['列表 1', '列表 2'],
      filterPlaceholder : '请输入搜索内容',
      noCheckedFormat : '共 {total} 项',
      hasCheckedFormat : '已选 {checked}/{total} 项'
    },
    image : {
      error : '加载失败'
    },
    pageHeader : {
      title : '返回'
    },
    popconfirm : {
      confirmButtonText : '确定',
      cancelButtonText : '取消'
    },
    empty : {
      description : '暂无数据'
    }
  }
}

