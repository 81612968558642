import Vue from 'vue'
import VueAMap from 'vue-amap'
Vue.use( VueAMap ) // 初始化vue-amap
window._AMapSecurityConfig = { securityJsCode : '8d4432aed8be5a96ed797b7eea5cdb52' }
VueAMap.initAMapApiLoader( {
  // 高德key
  key : '6b3e2660902feb1a7676dafc2cb978d0', // 高德 Key
  // 插件集合 （插件按需引入）
  plugin : [
    'AMap.Autocomplete', // 输入提示插件
    'AMap.PlaceSearch', // POI搜索插件
    'AMap.Scale', // 右下角缩略图插件 比例尺
    'AMap.OverView', // 地图鹰眼插件
    'AMap.ToolBar', // 地图工具条
    'AMap.MapType', // 类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
    'AMap.Geocoder',
    'AMap.Geolocation', // 定位控件，用来获取和展示用户主机所在的经纬度位置
    'AMap.MarkerClusterer',
    'AMap.AMapManager',
    'AMap.Marker'
  ],
  v : '1.4.15', // 插件
  uiVersion : '1.0.11' // ui版本号，也是需要写
} )
