export default {
  sysCustom : '{msg}', // 系统自定义
  i1 : '欢迎来到我的项目',

  /* ------------------   其他   ------------------*/
  WeiXinFollow : '请长按关注微信公众号',

  /* ------------------   通用组件   ------------------*/
  submit : '提交', // 提交按钮
  close : '关 闭', // 关闭按钮

  /* ------------------ 码主要内容展示 ------------------*/
  idisCode : '工业标识码',

  // 导航栏
  navigatorIndex : '主页',
  navigatorSaleAfter : '售后',
  navigatorCustomerService : '客服',

  // 产品信息
  productInfo : '产品信息',
  productName : '产品名称',
  batchNo : '产品批次',
  productNo : '产品编号',
  barCode : '产品条码',
  categoryName : '产品分类',
  unit : '产品单位',
  price : '产品价格',
  size : '尺寸',
  big : '大小',
  trace : '溯源查询',

  // 产品条码
  barCodeTitle : '产品条码',

  // 产品介绍
  productDesc : '产品介绍',

  // 产品价格
  productPrice : '产品价格',

  // 保质期
  ShelfLife : '保质期',
  days : '天数',
  remain : '剩余',
  expire : '过期',

  // 特别推荐
  SpecialRecommendation : '特别推荐',

  // 码上验证
  codeValidate : '码上验证',
  codeValidatePlaceholder : '请输入防伪码查询',
  codeValidateTips : '请输入查验码',
  codeValidateTipsErr : '验证码错误',
  loadMoreTips : '点击查看最近10条记录',
  successText : '感谢您的查询!',
  failureText : '您所查询的产品已被多次查询！',
  validTitle : '您所查询的产品是正品！',
  invalidTitle : '您所查询的产品已被多次查询！',
  successTemplate : '您所查询的{product}是正品，谢谢您的查询。',
  failureTemplate :
      '您所查询的产品是{product}，该产品已被查询 {count} 次。如果您的验证码多次查询非个人行为，请确认您的产品是否为官方渠道购买！',

  /* ------------------ 宫格-联系我们 ------------------*/
  // 联系我们
  contactUs : '联系我们',
  contactUs_name : '姓名',
  contactUs_name_placeholder : '请输入姓名',
  contactUs_phone : '联系方式',
  contactUs_phone_placeholder : '请输入联系方式',
  contactUs_remark : '备注',
  contactUs_remark_placeholder : '请输入备注 (0~150字)',
  contactUs_submit : '提交',

  /* ------------------ 宫格-报修管理 ------------------*/
  // 首页
  repair_title : '报修管理',
  repair_status_true : '售后保障中',
  repair_status_false : '保障已过期',
  repair_warranty_card : '我的质保卡',
  repair_warranty_to : '质保期至',
  repair_remain_days : '剩余报修天数',
  repair_apply : '售后申请',
  repair_history : '报修历史',
  repair_fault_check : '故障自查',
  repair_warranty_query_fail : '查询不到质保信息',

  // 提交报修页
  repair_create_title : '报修管理',
  repair_contact_info : '联系信息',
  repair_creator : '报修人',
  repair_creator_phone : '联系电话',
  repair_creator_address : '通讯地址',
  repair_detail : '故障详情',
  repair_detail_des : '详情描述',
  repair_detail_pic : '故障图片',
  repair_create_tips : '友情提示：',
  repair_create_tips1 : '1、为了能够快速为您进行售后服务，请如实填写',
  repair_create_tips2 : '2、提交服务工单后，请保持电话畅通，以便客服回访',
  repair_placeholder_creator : '请填写报修人',
  repair_placeholder_phone : '请填写联系电话',
  repair_placeholder_address : '请填写报修地址',
  repair_placeholder_detail : '请填写报修详情',

  // 报修历史
  repair_history_title : '报修历史',
  repair_status_wait : '待处理',
  repair_status_deal : '已处理',
  repair_status_mail_wait : '待邮寄',
  repair_status_mail_deal : '邮寄已处理',
  repair_status_cancel : '邮寄已处理',
  repair_time : '报修时间',
  repair_reason : '故障原因',
  repair_load_more : '点击加载更多',
  repair_no_more_data : '没有更多的数据了',

  // 报修详情
  repair_detail_title : '报修详情',
  repair_detail_status : '状态',
  repair_detail_no : '单号',
  repair_detail_time : '时间',
  repair_detail_detail : '处理详情',
  repair_detail_detail_time : '处理时间',
  repair_detail_detail_desc : '处理说明',
  repair_detail_detail_address : '邮寄地址',
  repair_detail_detail_express_company : '快递公司',
  repair_detail_detail_express_no : '快递单号',
  repair_detail_copy : '复制',
  repair_detail_product : '报修产品',
  repair_detail_product_batch : '生产批次',
  repair_detail_desc : '故障详情',
  repair_detail_desc_reason : '故障原因',
  repair_detail_desc_picture : '故障图片',
  repair_detail_contact : '联系信息',
  repair_detail_contact_name : '报修人',
  repair_detail_contact_phone : '联系电话',
  repair_detail_contact_address : '通讯地址',
  repair_detail_cancel : '取消报修',

  /* ------------------ 宫格-维保历史记录 ------------------*/
  code_maintenance_history_code : '工业标识',
  code_maintenance_history_product : '产品名称',
  code_maintenance_history_scan_time : '扫码时间',
  code_maintenance_history_scan_address : '扫码地点',

  /* ------------------ 宫格-质保 ------------------*/
  warranty_title : '质保',
  warranty_remark_title : '备注信息',
  warranty_bill_title : '购买凭证',
  warranty_create_title : '添加质保',

  // 质保详情
  warranty_product_warranty_card : '产品质保卡',
  warranty_product_name : '产品名称',
  warranty_idis_code : '工业标识',
  warranty_batch_no : '生产批次',
  warranty_buy_date : '购买日期',
  warranty_over_date : '质保期至',
  warranty_price : '产品价格',
  warranty_store_name : '经销门店',
  warranty_customer_name : '客户名称',
  warranty_customer_phone : '客户电话',
  warranty_customer_address : '详细地址',
  warranty_buy_prove : '购买凭证',
  warranty_remark_detail : '备注内容',
  warranty_tips : '请长按图片进行保存',

  // 创建质保单
  warranty_code : '产品标识',
  warranty_product : '产品信息',
  warranty_detail : '质保详情',
  warranty_contact : '联系信息',
  warranty_create_name : '用户姓名',
  warranty_placeholder_creator : '请输入姓名',
  warranty_create_phone : '联系电话',
  warranty_placeholder_phone : '请输入联系电话',
  warranty_create_address : '所在地址',
  warranty_placeholder_address : '请输入所在地址',
  warranty_create_date_buy : '购买日期',
  warranty_create_date_to : '保质期至',
  warranty_remark : '备注信息',
  warranty_create_price : '购买价格',
  warranty_placeholder_price : '请输入购买价格',
  warranty_create_choose_store : '选择门店',
  warranty_create_placeholder_choose_store : '请选择门店',
  warranty_pic : '购买凭证',
  warranty_placeholder_remark : '请输入备注信息',
  warranty_phone_alert : '请输入正确的电话号码!',
  warranty_price_alert : '请输入正确的价格!',

  /* ------------------ elementUi ------------------*/
  el : {
    colorpicker : {
      confirm : '确定',
      clear : '清空'
    },
    datepicker : {
      now : '此刻',
      today : '今天',
      cancel : '取消',
      clear : '清空',
      confirm : '确定',
      selectDate : '选择日期',
      selectTime : '选择时间',
      startDate : '开始日期',
      startTime : '开始时间',
      endDate : '结束日期',
      endTime : '结束时间',
      prevYear : '前一年',
      nextYear : '后一年',
      prevMonth : '上个月',
      nextMonth : '下个月',
      year : '年',
      month1 : '1 月',
      month2 : '2 月',
      month3 : '3 月',
      month4 : '4 月',
      month5 : '5 月',
      month6 : '6 月',
      month7 : '7 月',
      month8 : '8 月',
      month9 : '9 月',
      month10 : '10 月',
      month11 : '11 月',
      month12 : '12 月',
      // week: '周次',
      weeks : {
        sun : '日',
        mon : '一',
        tue : '二',
        wed : '三',
        thu : '四',
        fri : '五',
        sat : '六'
      },
      months : {
        jan : '一月',
        feb : '二月',
        mar : '三月',
        apr : '四月',
        may : '五月',
        jun : '六月',
        jul : '七月',
        aug : '八月',
        sep : '九月',
        oct : '十月',
        nov : '十一月',
        dec : '十二月'
      }
    },
    select : {
      loading : '加载中',
      noMatch : '无匹配数据',
      noData : '无数据',
      placeholder : '请选择'
    },
    cascader : {
      noMatch : '无匹配数据',
      loading : '加载中',
      placeholder : '请选择',
      noData : '暂无数据'
    },
    pagination : {
      goto : '前往',
      pagesize : '条/页',
      total : '共 {total} 条',
      pageClassifier : '页'
    },
    messagebox : {
      title : '提示',
      confirm : '确定',
      cancel : '取消',
      error : '输入的数据不合法!'
    },
    upload : {
      deleteTip : '按 delete 键可删除',
      delete : '删除',
      preview : '查看图片',
      continue : '继续上传'
    },
    table : {
      emptyText : '暂无数据',
      confirmFilter : '筛选',
      resetFilter : '重置',
      clearFilter : '全部',
      sumText : '合计'
    },
    tree : {
      emptyText : '暂无数据'
    },
    transfer : {
      noMatch : '无匹配数据',
      noData : '无数据',
      titles : ['列表 1', '列表 2'],
      filterPlaceholder : '请输入搜索内容',
      noCheckedFormat : '共 {total} 项',
      hasCheckedFormat : '已选 {checked}/{total} 项'
    },
    image : {
      error : '加载失败'
    },
    pageHeader : {
      title : '返回'
    },
    popconfirm : {
      confirmButtonText : '确定',
      cancelButtonText : '取消'
    },
    empty : {
      description : '暂无数据'
    }
  }
}

